@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--tg-theme-secondary-background-color, white);
  color: var(--tg-theme-text-color, black);
  /* scrollbar-color: grey transparent;
  
  scroll-behavior: smooth; */
}

blockquote {
  margin: 0;
}

blockquote p {
  padding: 15px;
  background: #eee;
  border-radius: 5px;
}

pre {
  overflow: auto;
}

* {
  touch-action: none;
}

/* ::-webkit-scrollbar {
  display: none; 
} */